import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
import router from '../router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    sid: '',
    isLoggedIn: false,
  },
  mutations: {
    SET_SID(state, sid) {
      state.sid = sid
      localStorage.setItem('sid', JSON.stringify(sid))
    },
    SET_IS_LOGGED(state, bool) {
      state.isLoggedIn = bool
    },
  },
  actions: {
    setSid(store, sid) {
      store.commit('SET_SID', sid)
    },
    setIsLogged(store, bool) {
      store.commit('SET_IS_LOGGED', bool)
    },
    async checkSession(store) {
      const sid = store.state.sid ? store.state.sid : JSON.parse(localStorage.getItem("sid"));
      try {
        const res = await api.checkSession({ sid: sid });
        console.log(res)
      } catch (error) {
        if (router.history.current.fullPath == '/panel') router.push('/admin-login')
      }
    },
  },
  modules: {
  },
})

export default store
