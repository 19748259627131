import image from '../assets/buds3_band6.png'

const gift = {
    gift_name: "Redmi Buds 3 Pro \n \n Mi Band 6 ",
    gift_imgUrl: image,
    gift_text_under_image: true,
}

export {
    gift,
}