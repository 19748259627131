<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import { mapActions } from "vuex";
export default {
  computed: {},
  methods: {
    ...mapActions(["checkSession"]),
    intervalSessionChecker() {
      setInterval(() => {
        this.checkSession();
      }, 600000);
    },
  },
  mounted() {
    this.intervalSessionChecker();
  },
};
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");
@font-face {
  font-family: "Xiaomi-Light";
  src: url("./assets/fonts/CamphorProForXiaoMi-Light.otf") format("OpenType");
}
@font-face {
  font-family: "Xiaomi-Regular";
  src: url("./assets/fonts/CamphorProForXiaoMi-Regular.otf") format("OpenType");
}
@font-face {
  font-family: "Xiaomi-Heavy";
  src: url("./assets/fonts/CamphorProForXiaoMi-Heavy.otf") format("OpenType");
}
@font-face {
  font-family: "Xiaomi-Thin";
  src: url("./assets/fonts/CamphorProForXiaoMi-Thin.otf") format("OpenType");
}
@font-face {
  font-family: "Xiaomi-Medium";
  src: url("./assets/fonts/CamphorProForXiaoMi-Medium.otf") format("OpenType");
}
@font-face {
  font-family: "Xiaomi-Bold";
  src: url("./assets/fonts/CamphorProForXiaoMi-Bold.otf") format("OpenType");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
}

#app {
  background-color: $background-color;
}
</style>
